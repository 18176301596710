import {onBeforeUnmount, onMounted, type Ref} from 'vue'

export function useClick({
                             el,
                             toggle,
                             busEvent = null
                         }: {
    el: Ref<HTMLElement | undefined>
    toggle: Ref<boolean>
    busEvent?: string | null
}) {
    const documentClick = (event: MouseEvent) => {
        let target = event.target as Element
        if (el && el.value && el.value !== target && !el.value.contains(target)) {
            toggle.value = false
        }
        // if (busEvent && busEvent === 'open_select') {
        //     if (el && el.value && el.value.classList.contains('open')) {
        //         EventBus.$emit('open_select', true)
        //     } else EventBus.$emit('open_select', false)
        // }
    }

    onMounted(() => {
        document.addEventListener('click', documentClick)
    })

    onBeforeUnmount(() => {
        document?.removeEventListener('click', documentClick)
    })
}
